import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Header } from 'components/theme';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { FormattedMessage } from "gatsby-plugin-intl"

export const Impressum = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="works">
      <Header />
      <SkillsWrapper as={Container}>
        <Details theme={theme}>
          <div>
            <h2>Impressum</h2> 
            <p>Cégadatok: Dentalprofi kft</p>
            <p>Telephely: 9400 Sopron, Bécsi u 85/g/2</p>  
            <p>Adószám: 29211955-2-08</p>
            <p>+36 70 61 11 427</p>
          </div>
          <div>
            <p>Tárhely Szolgáltató:</p>
            <p>Cégnév: Netlify Inc.</p>
            <p>Székhely Cím: 610 22nd St #315, San Francisco</p>
            <p>Posta Cím: 610 22nd St #315, San Francisco</p>
            <p>E-mail: <a href="mailto:support@netlify.com">support@netlify.com</a></p>
          </div>
          
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
